.w-5 {
    display: none;
}

.va-table-frozen {
    table {
        text-align: center;
        font-size : 12px;
    }

    .table-container {
        height    : 250px;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    table th,
    table td {
        white-space: nowrap;
    }

    table tr th:first-child,
    table td:first-child {
        position: sticky;
        right   : 0;
        z-index : 10;
    }

    table tr th:last-child,
    table td:last-child {
        position: sticky;
        left    : 0;
        z-index : 10;
    }

    table tr th:first-child {
        z-index: 110001;
    }

    table tr th,
    table tr th:last-child {
        position: sticky !important;
        top     : 0 !important;
        z-index : 1000;
    }



    tr td:first-child,
    tr td:last-child,
    tr[data-funcdetail-hour="sum"] td {
        background-color: #e2e3e5 !important;
    }

    #timetable tr:last-child td {
        position: sticky;
        bottom  : 0;
    }
}