.aa-add-form {
    margin-top: 10px;
}

.aa-add-form label {
    margin-bottom: 5px;
}

.aa-imageheader-form {
    width: 400px;
}

.error-handling {
    color: $aa-red-medium;
}

.ck-placeholder {
    text-align : right;
    font-size  : 1rem;
    font-weight: 400;
}

.ck-content p {
    text-align: right;
}