.chosen-drop {
    z-index: 1000000000000000 !important;
}

.va-chosen {
    .chosen-container-single {
        width: 100% !important;
    }

    .chosen-single {
        background: var(---nav-item) !important;
        background-color: var(--bg) !important;
        border: none !important;
        background-image: none !important;
        box-shadow: none !important;

        span {
            color: var(--nav-text);
        }

    }

    .chosen-drop {
        border: 1px solid var(--bg) !important;
        border-top: 0 !important;
        background: var(--bg) !important;
        box-shadow: none !important;
    }

    .chosen-results {
        color: var(--nav-text) !important;

    }

    li.highlighted {
        background-image: none !important;
        background-color: var(--nav-item) !important;
        color: var(--nav-selected-text) !important;
    }

    .chosen-search-input {
        border-color: var(--nav-item);
        color: var(--nav-text) !important;
    }

    .chosen-results li.no-results {
        background-color: var(--bg) !important;
        color: var(--nav-text) !important;
    }

    .chosen-single {
        height: 45px !important;

        span {
            margin-top: 10px;
        }

        div {
            top: 10px !important;
            right: -2px !important;
        }
    }
}