@font-face {
    font-family: IRANSansEn;
    font-style: normal;
    font-weight: bold;
    src: url("../../fonts/iransans/IRANSansWeb_Bold.eot");
    src: url("../../fonts/iransans/IRANSansWeb_Bold.eot") format("embedded-opentype"),
        url("../../fonts/iransans/IRANSansWeb_Bold.woff2") format("woff2"),
        url("../../fonts/iransans/IRANSansWeb_Bold.woff") format("woff"),
        url("../../fonts/iransans/IRANSansWeb_Bold.ttf") format("truetype");
}

@font-face {
    font-family: IRANSansEn;
    font-style: normal;
    font-weight: 500;
    src: url("../../fonts/iransans/IRANSansWeb_Medium.eot");
    src: url("../../fonts/iransans/IRANSansWeb_Medium.eot") format("embedded-opentype"),
        url("../../fonts/iransans/IRANSansWeb_Medium.woff2") format("woff2"),
        url("../../fonts/iransans/IRANSansWeb_Medium.woff") format("woff"),
        url("../../fonts/iransans/IRANSansWeb_Medium.ttf") format("truetype");
}

@font-face {
    font-family: IRANSansEn;
    font-style: normal;
    font-weight: 300;
    src: url("../../fonts/iransans/IRANSansWeb_Light.eot");
    src: url("../../fonts/iransans/IRANSansWeb_Light.eot") format("embedded-opentype"),
        url("../../fonts/iransans/IRANSansWeb_Light.woff2") format("woff2"),
        url("../../fonts/iransans/IRANSansWeb_Light.woff") format("woff"),
        url("../../fonts/iransans/IRANSansWeb_Light.ttf") format("truetype");
}

@font-face {
    font-family: IRANSansEn;
    font-style: normal;
    font-weight: 200;
    src: url("../../fonts/iransans/IRANSansWeb_UltraLight.eot");
    src: url("../../fonts/iransans/IRANSansWeb_UltraLight.eot") format("embedded-opentype"),
        url("../../fonts/iransans/IRANSansWeb_UltraLight.woff2") format("woff2"),
        url("../../fonts/iransans/IRANSansWeb_UltraLight.woff") format("woff"),
        url("../../fonts/iransans/IRANSansWeb_UltraLight.ttf") format("truetype");
}

@font-face {
    font-family: IRANSansEn;
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/iransans/IRANSansWeb.eot");
    src: url("../../fonts/iransans/IRANSansWeb.eot") format("embedded-opentype"),
        url("../../fonts/iransans/IRANSansWeb.woff2") format("woff2"),
        url("../../fonts/iransans/IRANSansWeb.woff") format("woff"),
        url("../../fonts/iransans/IRANSansWeb.ttf") format("truetype");
}

.english-num {
    font-family: IRANSansEn !important;
}
