@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

body {
    display: flex;
    // align-items: center;
    justify-content: space-around;
    height: 100vh;
}

.va-top-nav {
    background-color: var(--bg) !important;

    i {
        color: var(--nav-item);
    }
}

.va-main {
    display: flex;
    height: 100%;
    width: 100%;
}

.va-nav {
    height: 100%;
    width: 64px;
    background-color: var(--bg-panel);
    overflow-x: hidden;
    transition: width 300ms ease;

    &:hover {
        width: 300px;

        .nav__item.active {

            .nav__item-icon,
            .nav__item-name {
                box-shadow: 0px 0px 4px 1px #0005;
            }
        }
    }

    a {
        color: var(--nav-text);
    }

    .nav__container {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        flex-direction: column;
    }

    .nav__item {
        position: relative;
        display: flex;
        width: 100%;
        height: 40px;
        flex-shrink: 0;
        margin-top: 5px;
        margin-bottom: 5px;

        &.active {
            padding: 0 5px;

            &::after,
            &::before {
                opacity: 1;
            }

            * {
                color: var(--nav-selected-text);
                font-weight: 900;
            }

            .nav__item-icon {
                background-color: var(--nav-item);
                border-radius: 10px;
            }

            .nav__item-name {
                border-radius: 10px;
                background-color: var(--nav-text);
                margin-right: 10px;
            }
        }

        &::before {
            top: -30px;
            box-shadow: 15px 15px 0px 0px #eceff1;
        }

        &::after {
            bottom: -30px;
            box-shadow: 15px -15px 0px 0px #eceff1;
        }
    }

    .nav__item-name {
        display: flex;
        flex-grow: 1;
        height: 50px;
        align-items: center;
        justify-content: flex-start;
        padding-right: 15px;
        color: var(--nav-text);
        flex-shrink: 0;
    }

    .nav__item-icon {
        display: flex;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: space-around;
        flex-shrink: 0;

        ion-icon {
            font-size: 1.5em;
            color: #fff;
        }
    }
}

.va-content {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}



.va-profile-box {

    .va-profile {
        list-style: none;
        padding: 0 !important;
        margin: 0 !important;

    }

    .va-profile-name {
        width: max-content;
        color: var(--nav-text);
    }


    .dropdown-menu {
        padding: .7rem 0rem;
        font-size: .875rem;
        line-height: 22px;
        color: #5c5776;
        border: none;
        box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
        border-radius: .5rem;

    }

    .dropdown-menu {
        display: block;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        -webkit-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }

    .dropdown {
        &:hover {
            >.dropdown-menu {
                -webkit-transform: scaleY(1);
                -ms-transform: scaleY(1);
                transform: scaleY(1);
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .dropdown-submenu {
        &:hover {
            >.dropdown-menu {
                -webkit-transform: scaleY(1);
                -ms-transform: scaleY(1);
                transform: scaleY(1);
                opacity: 1;
                visibility: visible;
            }
        }
    }

    @media (min-width: 990px) {

        .dropright-lg {
            position: relative;

            .dropdown-menu {
                top: 0;
                right: auto;
                left: 100%;
                margin-top: 0;
                margin-right: 0.125rem;
            }
        }
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: ">";
        border-top: 0rem;
        border-right: 0rem;
        border-bottom: 0;
        border-left: 0rem;
        float: right;

    }

    .avatar-md {
        width: 56px;
        height: 56px;

    }

    .avatar img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }

    // avatar indicators
    .avatar {
        position: relative;
        display: inline-block;
        width: 3rem;
        height: 3rem;
        font-size: 1rem;
    }


    .avatar-online:before {
        background-color: green;
    }


    .avatar-indicators:before {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 5%;
        width: 30%;
        height: 30%;
        border-radius: 50%;
        border: 2px solid #fff;
        display: table;

    }

}