.va-tile-panel {
    background-color: var(--bg-tile-panel);
    padding: 25px;
    border-radius: 10px;
    color: var(--nav-text);

    i {
        font-size: 50px;
    }

    .va-tile-num {
        font-size: 20px;
        font-weight: 900;
    }

    .va-item-first {
        color: var(--tile-first);
    }

    .va-item-second {
        color: var(--tile-second);
    }

    .va-item-third {
        color: var(--tile-third);
    }

    .va-item-forth {
        color: var(--tile-forth);
    }
}