.aa-suggested-users {
    margin: 20px 0;
    button {
        margin-left: 10px;
    }

}

.aa-selected-users {
    button {
        margin-left: 10px;
    }
}