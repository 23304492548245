.va-sw-btn {

    input[type=checkbox] {
        /* styling for input element */
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        /* styling for labels */
        cursor: pointer;
        text-indent: -9999px;
        width: 52px;
        height: 27px;
        background: var(--nav-item);
        float: right;
        border-radius: 100px;
        position: relative;
    }

    label:after {
        /* styling for labels - on toggle */
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 20px;
        height: 20px;
        background: var(--bg);
        border-radius: 90px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
    }

    input:checked+label {
        /* conditional check while toggling */
        background: var(--nav-item);
    }

    input:checked+label:after {
        left: calc(100% - 5px);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    label:active:after {
        width: 45px;
    }
}