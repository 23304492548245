$aa-blue-dark: #3a6d8e;
$aa-blue-medium: #608da8;

$aa-red-medium: rgba(231, 39, 45, 1);
$aa-red-light: rgba(255, 0, 8, 1);

$aa-black-medium: rgba(31, 47, 59, 1);
$aa-white-light: rgba(255, 255, 254, 1);


.aa-dark-color {
    color: $aa-blue-dark;
}



html {
    --bg: #F8F9FA;
    --bg-panel: #FFF;
    --nav-text: #515052;
    --nav-item: #515052;
    --nav-selected-item: #F7F7F7;
    --nav-selected-text: #F7F7F7;
    --bg-tile-panel: #FFF;
    --tile-first: #FF4000;
    --tile-second: #133666;
    --tile-third: #FE6700;
    --tile-forth: #1385DA;
}

/* dark theme styling - Here, we set data-them as "dark"*/
html[data-theme='dark'] {
    --bg: #343434;
    --bg-panel: #272727;
    --nav-text: #F7F7F7;
    --nav-item: #F7F7F7;
    --nav-selected-item: #343434;
    --nav-selected-text: #343434;
    --bg-tile-panel: #272727;
    --tile-first: #ff0000;
    --tile-second: #0dcaf0;
    --tile-third: #FE6700;
    --tile-forth: #00ff41;
}

