.aa-add-form {
    margin-top: 10px;
}
.aa-add-form label {
    margin-bottom: 5px;
}
.aa-addbutton-form {
    margin-top: 10px;
    color: $aa-white-light;
    background-color: $aa-black-medium;
    border-color: $aa-black-medium;
}
.aa-addbutton-form:hover {
    color: $aa-white-light;
    background-color: $aa-black-medium;
    border-color: $aa-black-medium;
}
.aa-addbutton-form:focus {
    color: $aa-white-light;
    background-color: $aa-black-medium;
    border-color: $aa-black-medium;
    box-shadow: 0 0 0 0.25rem rgb(130 138 145 / 50%) !important;
}
.aa-table {
    margin-top: 10px;
    text-align: center;
}
.aa-table a {
    color: $aa-white-light;
}