.va-desktop-board {
    background-color: var(--bg-panel);
    padding: 35px 20px;
    border-radius: 10px;
}

.va-form {
    label {
        position: absolute;
        top: -2ex;
        z-index: 1;
        right: .5em;
        background-color: var(--bg-panel);
        border-radius: 50%;
        padding: 0 10px;
    }

    .form-group {
        position: relative;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        padding: 0.675rem 0.75rem;

        &:focus {
            box-shadow: none;
        }
    }
}