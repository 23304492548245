p {
    margin: 0;
}

h1 {
    font-size: 25px;
}

h2 {
    font-size: 23px;
}

h3 {
    font-size: 21px;
}

h4 {
    font-size: 19px;
}

h5 {
    font-size: 17px;
}

h6 {
    font-size: 15px;
}

p {
    font-size: 18px;
    line-height: 50px;
}

a {
    font-size: 14px;
    color: $aa-blue-dark;
    text-decoration: none !important;
    font-weight: 400;

    &:hover {
        color: $aa-blue-medium;
    }
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 21px;
    }

    h2 {
        font-size: 19px;
    }

    h3 {
        font-size: 17px;
    }

    h4 {
        font-size: 15px;
    }

    h5 {
        font-size: 13px;
    }

    h6 {
        font-size: 11px;
    }

    p {
        font-size: 16px;
        line-height: 34px;
    }

    a {
        font-size: 16px;
        color: $aa-blue-dark;

        &:hover {
            color: $aa-blue-medium;
        }
    }
}

@media screen and (max-width: 576px) {
    h1 {
        font-size: 17px;
    }

    h2 {
        font-size: 15px;
    }

    h3 {
        font-size: 13px;
    }

    h4 {
        font-size: 11px;
    }

    h5 {
        font-size: 9px;
    }

    h6 {
        font-size: 8px;
    }

    p {
        font-size: 14px;
        line-height: 32px;
    }

    a {
        font-size: 14px;
        color: $aa-blue-dark;
        text-decoration: none;

        &:hover {
            color: $aa-blue-medium;
        }
    }
}