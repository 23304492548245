/*#########################################################
#######                Bootstrap                 ##########
##########################################################*/
@import "~bootstrap/scss/bootstrap";

/*#########################################################
#######        Time Picker                       ##########
##########################################################*/
@import "./timepicker.scss";

/*#########################################################
#######        jQuery Time Picker                       ##########
##########################################################*/
// @import "./jquery-timepicker.scss";

/*#########################################################
#######               Fontawesome                ##########
##########################################################*/
@import "./fontawesome/fontawesome.scss";
/*#########################################################
#######               Malihu                     ##########
##########################################################*/
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
/*#########################################################
#######          Persian Date picker             ##########
##########################################################*/
@import "~persiandatepicker-jalali/css/persianDatepicker-default.css";
