.aa-btn-red-medium {
    color: $aa-white-light !important;
    background-color: $aa-red-medium !important;
    border-color: $aa-red-medium !important;
    &:hover {
        background-color: $aa-red-light !important;
        border-color: $aa-red-light !important;
    }
    &:focus {
        background-color: $aa-red-light !important;
        border-color: $aa-red-light !important;
        box-shadow: none !important;
    }
    &:active {
        background-color: $aa-red-light !important;
        border-color: $aa-red-light !important;
        &:focus {
            box-shadow: none !important;
        }
    }
}


.aa-btn-blue-medium {
    width: 10rem !important;
    color: $aa-white-light !important;
    background-color: $aa-blue-dark!important;
    border-color: $aa-blue-dark!important;
    &:hover {
        background-color: $aa-blue-medium !important;
        border-color: $aa-blue-medium !important;
    }
    &:focus {
        background-color: $aa-blue-medium !important;
        border-color: $aa-blue-medium !important;
        box-shadow: none !important;
    }
    &:active {
        background-color: $aa-blue-medium !important;
        border-color: $aa-blue-medium !important;
        &:focus {
            box-shadow: none !important;
        }
    }
}

.aa-btn-center{
    display: block !important;
    margin: 0 auto !important;
}

